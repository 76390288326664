import { render, staticRenderFns } from "./Countries_list.vue?vue&type=template&id=231a9a95&scoped=true&"
import script from "./Countries_list.vue?vue&type=script&lang=js&"
export * from "./Countries_list.vue?vue&type=script&lang=js&"
import style0 from "./Countries_list.vue?vue&type=style&index=0&id=231a9a95&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "231a9a95",
  null
  
)

export default component.exports